<template>
  <section style="min-height:500px" class="d-md-block container-pp-products-sm" id="products">
    <div class="container" id="content-pp-products">
      <div class="row justify-content-center">
        <div v-for="(product, index) in products" :key="index"
             class="col-6 col-sm-6 col-md-3 product-item">
          <div class="card h-100 d-flex flex-column justify-content-center" >
            <div class="card-header text-center">
              <img class="img-product-card-wrapper"
                   :src="product.producto_imagen.public_path"
                   :alt="product.producto_imagen.alt" :width="119" :height="117" loading="lazy">
            </div>
            <div class="card-body text-center">
              <div class="align-items-center card-title product-title-card align-items-center">
                {{ product.title }}
              </div>
              <div style="min-height: 105px" class="small" v-html="formatHtmlQuotationMarks(product.dinero_al_instante)"></div>
              <div class="d-block">
                <button @click="showProduct(product)" class="btn btn-primary btn-orange">
                  MÁS INFORMACIÓN
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <slot name="footer"></slot>
    </div>
  </section>
</template>

<script>
import FullFormatedText from "@/components/mixins/FullFormatedText";

export default {
  props: {
    products: {type: Array}
  },
  mixins: [FullFormatedText],
  methods: {
    showProduct(data) {
        let url = `/productos/${data.slug}`
        trackClick('Más_información_producto', data.slug)
        this.$emit('saveUserSearch', url)
        window.location.href = url
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-custom-lp-ben {
  background-color: #133C92 !important;
}

#ProductsTab {
  border-bottom: 0;
  @media (max-width: 570px) {
    margin-bottom: 20px !important;
  }

  .product-menu {
    width: 20%;
    padding: 0 5px;
    @media (max-width: 845px) {
      width: 33%;
    }
    @media (max-width: 570px) {
      width: 50%;
      margin-bottom: 6px !important;
    }
  }

  .product-menu-4 {
    width: 25%;
    padding: 0 5px;
    @media (max-width: 845px) {
      width: 50%;
    }
    @media (max-width: 570px) {
      width: 50%;
      margin-bottom: 6px !important;
    }
  }

  .product-menu {
    cursor: pointer;
    margin-bottom: 20px;

    .nav-link {
      border: 1px solid #EAEAEA;
      border-radius: 5px;
      text-align: center;
      font-size: 20px;
      font-weight: 800;
      color: #C8C8C8;
      padding: 4px 10px;
      width: 100% !important;
      @media (max-width: 1090px) {
        font-size: 18px;
      }
      @media (max-width: 946px) {
        font-size: 16px;
        padding: 10px 20px;
      }

      @media (max-width: 569px) {
        font-size: 14px;
        padding: 5px 10px;
      }
    }

    .active {
      background: rgb(252, 110, 166);
      background: linear-gradient(90deg, rgba(252, 110, 166, 1) 0%, rgba(247, 60, 109, 1) 100%);
      color: #fff;
    }
  }

}

#content-pp-products {
  .product-item {
    margin-bottom: 20px;

    .card-body {
      padding: 0px 15px !important;
      @media (max-width: 574px) {
        padding: 0px 4px !important;
      }

      .btn {
        @media (max-width: 1101px) {
          padding: 4px 14px !important;
          font-size: 14px !important;
        }
        @media (max-width: 893px) {
          font-size: 12px !important;
        }
        @media (max-width: 816px) {
          font-size: 10px !important;
        }
        @media (max-width: 767px) {
          padding: 6px 40px !important;
          font-size: 14px !important;
        }
        @media (max-width: 550px) {
          padding: 4px 14px !important;
          font-size: 10px !important;
        }
        @media (max-width: 370px) {
          padding: 4px 5px !important;
          font-size: 9px !important;
        }
      }
    }

    .card-header {
      background: #F3EBDA;
      border-bottom: 0;

      img {
        max-height: 117px;
      }
    }

    .product-title-card {
      font-weight: 600;
      text-transform: none;
      margin: 10px auto 4px;
      @media (max-width: 1005px) {
        height: 60px;
      }
      @media (max-width: 823px) {
        font-size: 18px;
      }
      @media (max-width: 780px) {
        height: 60px;
        font-size: 18px;
      }
      @media (max-width: 767px) {
        height: auto;
        font-size: 18px;
      }
      @media (max-width: 574px) {
        height: 47px;
        font-size: 14px;
      }
    }

    .txt-product {
      margin: 10px 0 0;
      min-height: 72px;
      font-weight: 600;

      @media (max-width: 936px) {
        min-height: 96px;
      }
      @media (max-width: 767px) {
        min-height: 50px;
      }
      @media (max-width: 574px) {
        min-height: 72px;
        font-size: 14px;
      }
    }
  }
}

.img-product-card-wrapper {
  height: 150px !important;
  width: 100%;
}
</style>
